@import 'shared/styles/colors.scss';
@import 'shared/styles/breakpoints.module.scss';
@import url('https://fonts.googleapis.com/css2?family=PT+Sans&display=block');
@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@1,700&family=PT+Sans&display=block');

.overrideModalContent {
    display: block;
}

.header {
    font-family: 'Kanit', 'sans-serif';
    font-style: italic;
    font-size: 4rem;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    line-height: 4.5rem;
    margin-bottom: 0.5rem;

    @media screen and (max-width: $DESKTOP_WIDTH) {
        font-size: 2.8rem;
        line-height: 3.2rem;
    }
}

.body {
    font-size: 1.8rem;
    text-align: center;
    line-height: 3rem;
    margin-bottom: 2rem;
}

.continueLink {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 3rem auto 1.5rem;
    box-sizing: border-box;

    width: 15rem;
    height: 5rem;
    font-weight: 700;
    font-style: italic;
    text-transform: capitalize;
    text-align: center;
    text-decoration: none;
    font-size: 1.8rem;
    letter-spacing: 0.1rem;
    color: $BLACK;
    background-color: $WHITE;
    border: 0.2rem solid $WHITE;

    transition: color 0.3s ease, background-color 0.3s ease;

    &:hover {
        color: $ACCENT_GOLD;
        background-color: $BLACK;
    }
}