$PRIMARY_RED: #B20607;
$PRIMARY_WHITE: #F0F0F0;

$SECONDARY_BLACK: #333333;
$SECONDARY_BLUE: #067AB2;

$ACCENT_GOLD: #DDB355;
$ACCENT_YELLOW: #FFFF6E;

$LIGHT_GRAY: #777777;

$BLACK: #000000;
$WHITE: #FFFFFF;

$GRADIENT_GOLD: linear-gradient(90deg, #deaf4f,#fbf0bd,#deaf4f);
$GRADIENT_RED: linear-gradient(0deg, $PRIMARY_RED 70%, #eb0707 100%);