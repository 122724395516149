@import 'shared/styles/breakpoints.module.scss';

.ratingImg {
    cursor: pointer;
    max-width: 8rem;
    height: auto;
}

// German Rating image contains smaller text so this is a one off case where the image needs to be larger
.germanRatingImg {
    @extend .ratingImg;
    max-width: none;
    width: 15rem;
}

.ratingDescription {
    font-size: 1.4rem;
}

.nonAusWrapper {
    cursor: pointer;
    text-align: center;
}