@import 'shared/styles/colors.scss';
@import 'shared/styles/breakpoints.module.scss';

$NOTCH_SIZE: 1.2rem;

.allNotch {
    clip-path: 
      polygon(
        0% $NOTCH_SIZE, 
        $NOTCH_SIZE 0%, 
        calc(100% - #{$NOTCH_SIZE}) 0%, 
        100% $NOTCH_SIZE, 
        100% calc(100% - #{$NOTCH_SIZE}), 
        calc(100% - #{$NOTCH_SIZE}) 100%, 
        $NOTCH_SIZE 100%, 
        0% calc(100% - #{$NOTCH_SIZE})
      );
}

.bottomNotch {
    clip-path: 
      polygon(
        0 0, 
        0 0, 
        0 0, 
        100% 0, 
        100% calc(100% - #{$NOTCH_SIZE}), 
        calc(100% - #{$NOTCH_SIZE}) 100%, 
        $NOTCH_SIZE 100%, 
        0% calc(100% - #{$NOTCH_SIZE})
      );
}

.topNotch {
    clip-path: 
      polygon(
        0% $NOTCH_SIZE, 
        $NOTCH_SIZE 0%, 
        calc(100% - #{$NOTCH_SIZE}) 0%, 
        100% $NOTCH_SIZE, 
        100% 100%, 
        0 100%, 
        0 100%, 
        0% 0
      );
}

.texture {
  background: url('/assets/images/texture.png');
  background-repeat: no-repeat;
  background-size: 35rem 35rem;
  background-position: -2rem -3rem;
}

.title {
    @extend .topNotch;
    font-weight: 800;
    padding: 2rem 19rem;
    background: $GRADIENT_RED;
    font-size: 2.8rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $PRIMARY_WHITE;
    border-bottom: solid 0.4rem $ACCENT_GOLD;

    @media screen and (max-width: $DESKTOP_WIDTH) {
      padding-left: 2rem;
      padding-right: 2rem;
    }
}
