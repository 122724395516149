@import 'shared/styles/breakpoints.module.scss';
@import 'shared/styles/colors.scss';

.modalRoot {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, .85)
}

.modalContent {
    padding: 3.2rem 8rem;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $BLACK;
    color: $WHITE;

    border: solid 0.2rem $WHITE;

    @media screen and (max-width: $DESKTOP_WIDTH) {
        display: block;
        padding: 3.2rem 4rem;
        height: 60vh;
        box-sizing: border-box;
        overflow-y: scroll;
    }
}

.closeButton {
    position: absolute;
    width: 5.8rem;
    height: 5.8rem;
    right: 2.4rem;
    top: 2.4rem;
    border: none;
    cursor: pointer;

    transition: color 0.3s ease, background-color 0.3s ease;
    border: solid 0.2rem $WHITE;
    background-color: white;

    &:hover {
        color: $ACCENT_GOLD;
        background-color: $BLACK;
    }
}