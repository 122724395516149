@mixin rotateAnimation($seconds) {
    animation: rotation $seconds infinite linear;
    @keyframes rotation {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(359deg);
        }
    }
}