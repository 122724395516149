@import 'shared/styles/animations.scss';

.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50rem;
}

.loadingPokeball {
  @include rotateAnimation(2s)
}