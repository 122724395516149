@import 'shared/styles/breakpoints.module.scss';
@import 'shared/styles/colors.scss';

.rootView {
    max-width: 110rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2.5rem;
    font-family: "PT Sans", "sans-serif";

    @media screen and (max-width: $DESKTOP_WIDTH) {
        margin: 2rem 1rem;
    }
}

.rootNotchedBox {
    background-color: #edeff0;
    padding-bottom: 0.5rem;
}

.siteBanner {
    display: flex;
    align-items: center;
    max-width: 80rem;
    padding: 6rem 0 3rem 0;
    margin: 0 auto;
    font-size: 2.2rem;

    @media screen and (max-width: $DESKTOP_WIDTH) {
        padding: 1rem;
        flex-wrap: wrap;
        justify-content: center;

        img {
            padding-top: 1rem;
        }
    }
}

.tcglLogo {
    position: absolute;
    height: 22rem;
    width: 22rem;
    top: 13rem;
    z-index: 1;

    @media screen and (max-width: $DESKTOP_WIDTH) {
        display: none;
    }
}

.coinImage {
    width: 20rem;
    height: 20rem;

    @media screen and (max-width: $DESKTOP_WIDTH) {
        display: none;
    }
}

.moduleNotchedBox {
    position: relative;
    background-color: white;
    margin: 0 1rem 1rem 1rem;
    padding: 1rem 11rem;

    @media screen and (max-width: $DESKTOP_WIDTH) {
        padding: 0.5rem;
    }
}

.messageToUser {
    box-sizing: border-box;
    max-width: 80rem;
    padding: 0.5rem;
    margin: 1rem auto;
    border: solid 0.1rem $PRIMARY_RED;
    font-size: 1.8rem;
    text-align: center;

    @media screen and (max-width: $DESKTOP_WIDTH) {
        margin: 1rem;
    }
}

.canRedeemError {
    @extend .messageToUser;
    border: solid 0.1rem $PRIMARY_RED;
}

.rainierConfirmationMessage {
    @extend .messageToUser;
    border: solid 0.1rem $SECONDARY_BLUE;
}

.desktopGameRating {
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    white-space: nowrap;

    @media screen and (max-width: $DESKTOP_WIDTH) {
        display: none;
    }
}

.showRating {
    display: flex;
}